const GiftsPrice = () => {
  return (
    <div className="price-gifts-container">
      <h2>PRESENTES POR PREÇO</h2>
      <div className="links-wrapper">
        <a href="https://www.decathlon.com.br/collection/?q=926&O=OrderByTopSaleDESC">
          ATÉ R$ 49,99
        </a>
        <a href="https://www.decathlon.com.br/collection/?q=927&O=OrderByTopSaleDESC">
          ATÉ R$ 69,99
        </a>
        <a href="https://www.decathlon.com.br/collection/?q=928&O=OrderByTopSaleDESC">
          ATÉ R$ 99,99
        </a>
      </div>
    </div>
  )
}

export default GiftsPrice
